<template>
  <div class="container pt-3">
    <Breadcrumb class="m-0">
      <template slot="restPage">
        <el-breadcrumb-item>
          Profile
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="box-card mt-3">
      <div class="titleStyle">
        <h1 class="primaryColor" style="font-size: 30px;">
          {{ title }}
        </h1>
        <el-divider></el-divider>
        <el-tabs v-model="activeName" type="border-card">
          <el-tab-pane :label="$t('userProfile.basicInfo')" name="info">
            <Heading :content="$t('userProfile.basic.title')">
              <template slot="button">
                <el-button type="success" size="mini" @click="editProfile">
                  <i class="fas fa-edit" />
                  Edit
                </el-button>
              </template>
            </Heading>
            <hr />
            <el-form
              v-if="userInfo"
              ref="form"
              :model="userInfo"
              label-position="left"
              label-width="120px"
            >
              <el-form-item :label="'Name'">
                <b class="text-success">{{ title }}</b>
              </el-form-item>
              <el-form-item label="Country">
                <div class="showMode" v-if="userInfo.country">
                  <vue-country-intl
                    type="country"
                    v-model="userInfo.country"
                    placeholder="Select Country"
                    disabled="disabled"
                  ></vue-country-intl>
                </div>
                <span v-else>-</span>
              </el-form-item>
            </el-form>
            <ProfileDialog
              ref="ProfileDialog"
              role="educator"
              :userInfo="userInfo"
              @getUserProfile="getUserProfile"
            ></ProfileDialog>
          </el-tab-pane>
          <el-tab-pane label="Performance" name="performance">
            <Heading content="Performance"></Heading>
            <hr />
            <PerformanceBox
              v-if="userId && activeName === 'performance'"
              :userId="userId"
            ></PerformanceBox>
          </el-tab-pane>
          <el-tab-pane label="My Plans" name="plans">
            <Heading content="My Plans"></Heading>
            <hr />
            <MyPlans
              v-if="myPlans"
              :myPlans="myPlans"
              role="educator"
              @getPlans="getUserPlans"
            ></MyPlans>
          </el-tab-pane>
          <el-tab-pane label="Account Setting" name="account">
            <Heading content="Account Setting"></Heading>
            <hr />
            <el-form label-position="left" label-width="120px">
              <el-form-item label="Email">
                <span>
                  {{ userInfo.email }}
                </span>
                <template>
                  <el-button
                    class="ml-2"
                    type="text"
                    @click="editEmail"
                    size="mini"
                  >
                    <i class="fas fa-edit" />
                    Edit
                  </el-button>
                </template>
              </el-form-item>
              <el-form-item label="Password">
                ********************
                <el-button
                  class="ml-2"
                  type="text"
                  @click="editPassword"
                  size="mini"
                >
                  <i class="fas fa-key"></i>
                  Change Password
                </el-button>
              </el-form-item>
            </el-form>
            <AccountDialog
              ref="AccountDialog"
              :email="userInfo.email"
              @getUserProfile="getUserProfile"
            ></AccountDialog>
            <PasswordDialog ref="PasswordDialog"></PasswordDialog>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { instant, user } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import userApi from "@/apis/user";
import Theme from "@/common/theme";
import moment from "moment";
import MyPlans from "@/views/profile/components/MyPlans.vue";
import ProfileDialog from "@/views/profile/components/ProfileDialog.vue";
import AccountDialog from "@/views/profile/components/AccountDialog.vue";
import PasswordDialog from "@/views/profile/components/PasswordDialog.vue";
import PerformanceBox from "@/views/profile/components/PerformanceBox.vue";
import Common from "@/mixins/common.js";

export default {
  metaInfo() {
    return {
      title: `${this.title} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb,
    MyPlans,
    ProfileDialog,
    AccountDialog,
    PasswordDialog,
    PerformanceBox
  },

  mixins: [Common],

  props: [],
  data() {
    return {
      moment,
      myPlans: null,
      activeName: "info",
      userInfo: null,
      userProfile: null
    };
  },
  computed: {
    ...mapGetters("user", ["token", "getProfile"]),
    theme() {
      return Theme;
    },
    userId() {
      return this.getProfile.id;
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    teacherViewUser() {
      return (
        this.$route.name == "TeacherViewUser" ||
        this.$route.name == "CompanyAdminViewUser"
      );
    },
    myProfile() {
      return this.$route.name === "UserProfile";
    },
    adminViewUser() {
      return this.$route.name == "AdminViewUser";
    },
    title() {
      if (this.userInfo) {
        return user.displayName(
          this.userInfo.first_name,
          this.userInfo.last_name
        );
      } else {
        return " - ";
      }
    }
  },
  watch: {
    userId(userId) {
      if (userId) {
        this.getUserProfile();
      }
    }
  },
  mounted() {
    if (this.$route.name === "UserProfile") {
      this.activeName = "info";
    }
    if (this.$route.query.active) {
      this.activeName = this.$route.query.active;
    }
    if (this.userId) {
      this.getUserProfile();
    }
  },
  methods: {
    editProfile() {
      this.$refs.ProfileDialog.openEditUserDialog();
    },
    editEmail() {
      this.$refs.AccountDialog.openEditUserDialog();
    },
    editPassword() {
      this.$refs.PasswordDialog.openEditUserDialog();
    },
    async getUserProfile() {
      const res = await userApi.getProfile();
      this.userInfo = res.user;
      this.userProfile = res.user;
      this.getUserPlans();
    },
    async getUserPlans() {
      const res = await userApi.getUserPlans(this.userId, {
        status: "active"
      });
      this.myPlans = res;
    }
  }
};
</script>

<style scoped>
.referral-code span {
  display: inline-block;
  border: 1px solid #ebeef5;
  padding: 0 20px;
  margin-right: 10px;
  font-size: 1rem;
  height: 42px;
  line-height: 42px;
}
.box-card {
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.proIcon {
  display: inline-block;
  margin: 0px 2px;
  color: orange;
}
</style>

<style scoped>
::v-deep .showMode .vue-country-intl .country-intl-input-wrap {
  border: none !important;
}
::v-deep .showMode .prevent-click,
::v-deep .showMode .dropdown-flag {
  display: none;
}
::v-deep .showMode .vue-country-intl .country-intl-label {
  width: auto;
  padding: 0;
  line-height: 40px;
}
::v-deep .vue-country-disabled .country-intl-label span {
  opacity: 1;
}
</style>
